var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardInformationSettingsStyled', [_c('DropdownStyled', [_c('BasicSelect', {
    staticClass: "basic-select",
    attrs: {
      "options": _vm.classOptions,
      "selected": _vm.selectedClassOption,
      "target": _vm.$t('dashboardInformationSettings.select'),
      "isDisabled": !_vm.hasTalpaRoles
    },
    on: {
      "change": _vm.setClassOption
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }